import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import { Col, Row, Select } from 'antd'
import ScoreRadarChart from '../graph/ScoreRadarChart'
import { useEffect, useState } from 'react'
import {
  ReportTestScoreChartByCategory,
  SchoolCategory,
  useReportTestScoreChartSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

const scoreTypes = {
  totalScore: '得点合計',
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
}

const maleColor = '#0000FF'
const femaleColor = '#FFAAAA'

export type ScoreData = {
  subject: string
  national: number
  prefecture: number
}

type ScoreDataByGender = {
  gender: UserGender
  scores: ScoreData[]
}

type ScoreDataByGrade = {
  schoolCategory: SchoolCategory
  grade: number
  genders: ScoreDataByGender[]
}

type GraphProps = {
  prefectureCode: number
  cityCode: number
  year: number
  forPrinting: boolean
}

const ReportScoreGraph = ({
  prefectureCode,
  cityCode,
  year,
  forPrinting,
}: GraphProps) => {
  const [
    getReportForMale,
    { data: dataForMale, loading: loadingForMale, called: calledForMale },
  ] = useReportTestScoreChartSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale, called: calledForFemale },
  ] = useReportTestScoreChartSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [graphData, setGraphData] = useState<ScoreDataByGrade[]>([])

  useEffect(() => {
    if (prefectureCode === 0) return
    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, cityCode, year, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (loadingForMale || loadingForFemale) return
    if (!dataForMale || !dataForFemale) return
    if (
      !dataForMale.reportTestScoreChartSummary ||
      !dataForFemale.reportTestScoreChartSummary
    )
      return
    if (
      !dataForMale.reportTestScoreChartSummary.data ||
      !dataForFemale.reportTestScoreChartSummary.data
    ) {
      setGraphData([])
      return
    }

    const data: ScoreDataByGrade[] = []
    const categories = [
      SchoolCategory.ElementarySchool,
      SchoolCategory.JuniorHighSchool,
      // SchoolCategory.HighSchool,
    ]
    const userGenders = [UserGender.Male, UserGender.Female]
    categories.forEach((schoolCategory) => {
      const maxGrade =
        schoolCategory === SchoolCategory.ElementarySchool ? 6 : 3
      for (let grade = 1; grade <= maxGrade; grade++) {
        data.push({
          schoolCategory,
          grade,
          genders: userGenders.map((gender) => {
            return {
              gender,
              scores: [],
            }
          }),
        })
      }
    })
    const schools = {
      elementarySchoolReport: SchoolCategory.ElementarySchool,
      juniorHighSchoolReport: SchoolCategory.JuniorHighSchool,
      // highSchoolReport: SchoolCategory.HighSchool,
    }
    Object.keys(schools).forEach((reportKey) => {
      const sc = schools[reportKey]
      userGenders.forEach((gender) => {
        const target = gender === UserGender.Male ? dataForMale : dataForFemale
        const targetData: ReportTestScoreChartByCategory =
          target.reportTestScoreChartSummary?.data![reportKey]
        targetData.reports?.forEach((r) => {
          const { grade, prefecture } = r
          if (!prefecture) return
          const destData = data.find((d) => {
            return d.schoolCategory === sc && d.grade === grade
          })
          if (!destData) return
          const destDataByGender = destData.genders.find(
            (g) => g.gender === gender,
          )
          if (!destDataByGender) return
          Object.keys(scoreTypes).forEach((sc) => {
            if (!r.prefecture) return
            destDataByGender.scores.push({
              subject: scoreTypes[sc],
              prefecture: prefecture[sc]?.toFixed(2),
              national: 50,
            })
          })
        })
      })
    })
    setGraphData(data)
  }, [dataForMale, dataForFemale, loadingForMale, loadingForFemale])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (!calledForMale || !calledForFemale) {
    return null
  }

  if (graphData.length === 0) {
    return <NoData />
  }

  return (
    <>
      {graphData.map((data) => (
        <Row
          gutter={10}
          key={`${data.schoolCategory}_${data.grade}`}
          style={{ pageBreakAfter: 'always' }}
        >
          {data.genders.map((g) => {
            let schoolName = '小学'
            if (data.schoolCategory === SchoolCategory.JuniorHighSchool)
              schoolName = '中学'
            else if (data.schoolCategory === SchoolCategory.HighSchool)
              schoolName = '高校'
            const genderName = g.gender === UserGender.Male ? '男子' : '女子'
            const radarColor =
              g.gender === UserGender.Male ? maleColor : femaleColor
            const title = `${schoolName}${data.grade}年生/${genderName}`
            return (
              <Col
                span={forPrinting ? 24 : 12}
                style={{ marginBottom: 10 }}
                key={title}
              >
                <ScoreRadarChart
                  title={title}
                  data={g.scores}
                  radarColor={radarColor}
                  forPrinting={forPrinting}
                />
              </Col>
            )
          })}
        </Row>
      ))}
    </>
  )
}

const ReportScoreRadarChartPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8
  const [year, setYear] = useState(2024)

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">Tスコアによる全国平均値による比較</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ width: 900 }}>
            <div className="space-y-4 pb-8">
              <Select value={year} onChange={(v) => setYear(v)}>
                <Option value={2010}>2010 ({makeYearName(2010)})</Option>
                <Option value={2011}>2011 ({makeYearName(2011)})</Option>
                <Option value={2012}>2012 ({makeYearName(2012)})</Option>
                <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                <Option value={2020}>2020 ({makeYearName(2020)})</Option>
                <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                <Option value={2023}>2023 ({makeYearName(2023)})</Option>
                <Option value={2024}>2024 ({makeYearName(2024)})</Option>
              </Select>
            </div>

            <ReportScoreGraph
              prefectureCode={prefectureCode}
              cityCode={city.code}
              year={year}
              forPrinting={false}
            />
          </div>

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>Tスコアによる{makeYearName(year - 1)}年度全国平均値との比較</div>
        <ReportScoreGraph
          prefectureCode={prefectureCode}
          cityCode={city.code}
          year={year}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportScoreRadarChartPage
