import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  ReportTestByGrade,
  ReportTestSummary,
  useReportTestSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import { Radio, Select } from 'antd'
import { useEffect, useState } from 'react'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

type ReportTableDataProps = {
  data: ReportTestByGrade
  showType: string
  className: string
}

const ReportTableData = ({
  data,
  showType,
  className,
}: ReportTableDataProps) => {
  const formatter = new Intl.NumberFormat('ja-JP')
  const report = data[showType]
  return (
    report && <tr className={className}>
      <td className={className}>{data?.grade}</td>
      <td className={className}>{formatter.format(report.grip?.toFixed(2))}</td>
      <td className={className}>
        {formatter.format(report.sitUps?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.bending?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.sideJump?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.shuttleRun?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.sprintRun?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.standingJump?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.handballThrow?.toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format(report.totalScore?.toFixed(2))}
      </td>
    </tr>
  )
}

const styleForReport = {
  fontSize: 11,
  width: 90,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className="border border-white">
        <th className={className} style={{ fontSize: 12, width: 96 }}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={styleForReport}>
          握力
          <br />
          (kg)
        </th>
        <th className={className} style={styleForReport}>
          上体起こし
          <br />
          (回)
        </th>
        <th className={className} style={styleForReport}>
          長座体前屈
          <br />
          (cm)
        </th>
        <th className={className} style={styleForReport}>
          反復横跳び
          <br />
          (点)
        </th>
        <th className={className} style={styleForReport}>
          シャトルラン
          <br />
          (回)
        </th>
        <th className={className} style={styleForReport}>
          50m走
          <br />
          (秒)
        </th>
        <th className={className} style={styleForReport}>
          立ち幅跳び
          <br />
          (cm)
        </th>
        <th className={className} style={styleForReport}>
          ボール投げ
          <br />
          (m)
        </th>
        <th className={className} style={styleForReport}>
          得点合計
          <br />
          (点)
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({
  className,
  data,
  showType,
}: {
  className: string
  data: ReportTestSummary
  showType: string
}) => {
  if (!data) return null

  return (
    <tbody>
      <tr>
        <th rowSpan={7} className={className}>
          小学校
        </th>
      </tr>
      {data.elementarySchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          中学校
        </th>
      </tr>
      {data.juniorHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      {/* <tr>
        <th rowSpan={4} className={className}>
          高校
        </th>
      </tr>
      {data.allDayHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={5} className={className}>
          高校
          <br />
          (定時制)
        </th>
      </tr>
      {data.fixTimeHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))} */}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  cityCode: number
  year: number
  gender: UserGender
  showType: string
}

const ReportTestTable = ({
  prefectureCode,
  cityCode,
  year,
  gender,
  showType,
}: TableProps) => {
  const [getReport, { data, loading, called }] = useReportTestSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (prefectureCode === 0) return

    const variables = {
      input: {
        prefectureCode,
        cityCode,
        year,
        gender: gender === UserGender.Male ? 1 : 2,
      },
    }
    getReport({ variables })
  }, [prefectureCode, cityCode, year, gender, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportTestSummary || !data.reportTestSummary.data) {
    if (called) {
      return <NoData />
    } else {
      return null
    }
  }
  const tableClasses =
    gender === UserGender.Male ? 'table-border-male' : 'table-border-female'

  return (
    <table className="table border-collapse">
      <TableHeader className={tableClasses} />
      <TableBody
        className={tableClasses}
        data={data.reportTestSummary.data}
        showType={showType}
      />
    </table>
  )
}

const ReportTestPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8
  const [showType, setShowType] = useState('average')
  const [year, setYear] = useState(2024)
  const [gender, setGender] = useState(UserGender.Male)

  const handleChangeShowType = (e) => {
    setShowType(e.target.value)
  }

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">
          体力テスト測定項目の平均値と標準偏差
        </div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="space-y-4 pb-8">
            <div className="space-x-4">
              <Select value={year} onChange={(v) => setYear(v)}>
                <Option value={2010}>2010 ({makeYearName(2010)})</Option>
                <Option value={2011}>2011 ({makeYearName(2011)})</Option>
                <Option value={2012}>2012 ({makeYearName(2012)})</Option>
                <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                <Option value={2020}>2020 ({makeYearName(2020)})</Option>
                <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                <Option value={2023}>2023 ({makeYearName(2023)})</Option>
                <Option value={2024}>2024 ({makeYearName(2024)})</Option>
              </Select>

              <Select value={gender} onChange={(v) => setGender(v)}>
                <Option value={UserGender.Male}>男子</Option>
                <Option value={UserGender.Female}>女子</Option>
              </Select>
            </div>

            <div>
              <Radio.Group onChange={handleChangeShowType} value={showType}>
                <Radio value="average" className="dark:text-white">
                  平均値
                </Radio>
                <Radio value="deviation" className="dark:text-white">
                  標準偏差
                </Radio>
              </Radio.Group>
            </div>
          </div>

          <ReportTestTable
            prefectureCode={prefectureCode}
            cityCode={city.code}
            year={year}
            gender={gender}
            showType={showType}
          />

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>体力テスト測定項目の平均値と標準偏差</div>
        <div>{showType === 'average' ? '平均値' : '標準偏差'}</div>
        <div>{gender === UserGender.Male ? '男子' : '女子'}</div>
        <ReportTestTable
          prefectureCode={prefectureCode}
          cityCode={city.code}
          year={year}
          gender={gender}
          showType={showType}
        />
      </div>
    </Dashboard>
  )
}

export default ReportTestPage
