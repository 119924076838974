import { useEffect } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import {
  ReportTestRankTargetSummary,
  SchoolCategory,
  useReportTestRankTargetSummaryLazyQuery,
} from '@alpha/core'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

type ReportTestRankTargetByCategory = {
  schoolCategory: SchoolCategory
  rates: number[]
}

export type ReportTestRankTarget = {
  year: number
  totalRates: number[]
  schoolCategories: ReportTestRankTargetByCategory[]
}

const styleForReport = {
  fontSize: 14,
  width: 64,
}

const TableHeader = () => {
  return (
    <thead>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" rowSpan={2} style={styleForReport}>
          年度
        </th>
        <th className="table-border-yellow" colSpan={3}>
          A+Bの割合
        </th>
        <th className="table-border-yellow" colSpan={3}>
          D+Eの割合
        </th>
      </tr>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" style={styleForReport}>
          全体
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        {/* <th className="table-border-yellow" style={styleForReport}>
          高校
        </th> */}
        {/* <th className="table-border-yellow" style={styleForReport}>
          全体
        </th> */}
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        {/* <th className="table-border-yellow" style={styleForReport}>
          高校
        </th> */}
      </tr>
    </thead>
  )
}

const fixedRates = {
  "鹿嶋市": {
    'R4': [49.6, 47.6, 54.7, 23.4, 17.3],
    'R5': [50.5, 48.2, 56.2, 23.9, 17.4],
    'R6': [49.6, 47.0, 56.0, 25.4, 18.0],
  },
  "高萩市": {
    'R4': [51.2, 46.6, 60.7, 25.0, 15.7]
  },
  "筑西市": {
    'R4': [49.1, 42.4, 63.7, 26.5, 13.4]
  },
  "石岡市": {
    'R4': [42.4, 42.4, 42.6, 27.8, 27.4]
  },
  "河内町": {
    'R4': [48.1, 45.7, 52.3, 27.4, 25.0]
  },
  "桜川市": {
    'R4': [61.7, 59.7, 65.5, 17.0, 12.9],
    'R6': [67.0, 67.8, 65.7, 11.3, 13.3],
  },
  "東海村": {
    'R4': [53.0, 52.2, 54.5, 18.7, 19.8]
  },
  "常陸太田市": {
    'R4': [58.3, 57.2, 60.7, 15.9, 16.6],
    'R6': [59.0, 56.3, 64.9, 18.2, 13.1],
  },
  "古河市": {
    'R4': [43.1, 39.2, 51.8, 28.6, 22.0],
    'R6': [45.0, 41.9, 52.1, 28.7, 22.3],
  },
  "小美玉市": {
    'R4': [47.9, 44.7, 54.4, 24.3, 19.1],
    'R3': [53.5, 50.5, 58.9, 18.8, 15.9],
    'R1': [60.3, 59.3, 62.2, 15.3, 15.3],
    'H30': [60.6, 59.0, 63.7, 14.7, 13.6],
    'H29': [61.4, 61.5, 61.2, 13.4, 13.7],
    'H28': [59.4, 59.1, 60.1, 14.3, 14.3],
    'H27': [58.7, 59.0, 57.9, 15.6, 16.0],
    'H26': [58.1, 58.3, 57.9, 15.5, 15.9],
    'H25': [58.1, 58.3, 57.9, 15.5, 15.9],
    'H24': [54.2, 54.6, 53.4, 16.8, 18.0],
    'H23': [54.2, 54.6, 53.4, 16.8, 18.0],
    'H22': [53.7, 53.7, 53.6, 16.7, 16.1]
  }
}

const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}


type TableBodyProps = {
  data: ReportTestRankTargetSummary
}

const TableBody = ({ data }: TableBodyProps) => {
  let reports = data.reports?.slice().sort((a, b) => b.year! - a.year!)

  if (!reports) {
    return null
  }

  const cityName = getCity()?.name

  const rates: {[key: string]: any} = Object.fromEntries(
      reports.map(data => {
        const yearName = makeYearName(data.year!)
        return [
          yearName,
          fixedRates[cityName]?.[yearName] ?? [
            data.totalRates![0] + data.totalRates![1],
            data.elementarySchoolRates![0] + data.elementarySchoolRates![1],
            data.juniorHighSchoolRates![0] + data.juniorHighSchoolRates![1],
            data.elementarySchoolRates![3] + data.elementarySchoolRates![4],
            data.juniorHighSchoolRates![3] + data.juniorHighSchoolRates![4],
          ]
        ]
      })
    )

  return (
    <tbody>
      {Object.entries(rates).map(([key, data]) => {
        return (
          <tr className="table-border-yellow" key={key}>
            <th className="table-border-yellow">
              {key}
            </th>
            <td className="table-border-yellow">
              {data[0]?.toFixed(1)}%
            </td>

            <td className="table-border-yellow">
              {data[1]?.toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {data[2]?.toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {data[3]?.toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {data[4]?.toFixed(1)}
              %
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  cityCode: number
}

const ReportTestRankTargetTable = ({
  prefectureCode,
  cityCode,
}: TableProps) => {
  const [getReport, { data, loading }] =
    useReportTestRankTargetSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (prefectureCode === 0) return
    getReport({
      variables: {
        input: {
          prefectureCode,
          cityCode,
        },
      },
    })
  }, [prefectureCode, cityCode, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportTestRankTargetSummary?.data) {
    return <NoData />
  }

  let reportData = data.reportTestRankTargetSummary.data

  return (
    <>
      <table className="table border-collapse">
        <TableHeader />
        <TableBody data={reportData} />
      </table>
    </>
  )
}

const ReportTestRankTargetPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数の割合の推移</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div className="pt-4 pb-2">
            <span className="border border-black">目標水準</span>
            「体力テストにおける総合評価Ａ及びＢの割合」
            <br />
            平成２７年度 55.0％ ⇒
            <span className="border border-black">60.0％</span>
            （令和２年度目標値）
          </div>

          <div className="font-bold">
            ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
          </div>

          <ReportTestRankTargetTable
            prefectureCode={prefectureCode}
            cityCode={city.code}
          />

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県{city.name}児童生徒の体力・運動能力調査結果</div>
        <div>総合評価段階別人数の割合の推移</div>

        <div className="pt-4 pb-2">
          <span className="border border-black">目標水準</span>
          「体力テストにおける総合評価Ａ及びＢの割合」
          <br />
          平成２７年度 55.0％ ⇒
          <span className="border border-black">60.0％</span>
          （令和２年度目標値）
        </div>

        <div className="font-bold">
          ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
        </div>

        <ReportTestRankTargetTable
          prefectureCode={prefectureCode}
          cityCode={city.code}
        />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankTargetPage
