import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  ReportBody,
  useReportBodySummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

const ReportTableData = ({
  data,
  className,
}: {
  data: ReportBody
  className: string
}) => {
  if (!data) return null
  const formatter = new Intl.NumberFormat('ja-JP')
  return (
    <tr className={className}>
      <td className={className}>{data.grade}</td>
      <td className={className}>{formatter.format(data.sampleCount!)}</td>
      <td className={className}>{data.heightAvg?.toFixed(2)}</td>
      <td className={className}>{data.weightAvg?.toFixed(2)}</td>
      <td className={className}>{data.heightDev?.toFixed(2)}</td>
      <td className={className}>{data.weightDev?.toFixed(2)}</td>
    </tr>
  )
}

type TableProps = {
  prefectureCode: number
  cityCode: number
  year: number
  gender: UserGender
}

const ReportBodyTable = ({
  prefectureCode,
  cityCode,
  year,
  gender,
}: TableProps) => {
  const [getReport, { data, loading, called }] = useReportBodySummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (prefectureCode === 0) return

    const variables = {
      input: {
        prefectureCode,
        cityCode,
        year,
        gender: gender === UserGender.Male ? 1 : 2,
      },
    }
    getReport({ variables })
  }, [prefectureCode, cityCode, year, gender, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportBodySummary || !data.reportBodySummary.data) {
    if (called) {
      return <NoData />
    } else {
      return null
    }
  }

  const tableClasses =
    gender === UserGender.Male ? 'table-border-male' : 'table-border-female'
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    // allDayHighSchoolReport,
    // fixTimeHighSchoolReport,
  } = data.reportBodySummary.data

  return (
    <table className="table border-collapse">
      <thead>
        <tr className="border border-white">
          <th rowSpan={2} className={tableClasses}>
            校種
          </th>
          <th rowSpan={2} className={tableClasses}>
            学年
          </th>
          <th rowSpan={2} className={tableClasses}>
            標本数
          </th>
          <th colSpan={2} className={tableClasses}>
            平均値
          </th>
          <th colSpan={2} className={tableClasses}>
            標準偏差
          </th>
        </tr>
        <tr className="border border-white">
          <th className={tableClasses}>身長(cm)</th>
          <th className={tableClasses}>体重(kg)</th>
          <th className={tableClasses}>身長(cm)</th>
          <th className={tableClasses}>体重(kg)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th rowSpan={7} className={tableClasses}>
            小学校
          </th>
        </tr>
        {elementarySchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        <tr>
          <th rowSpan={4} className={tableClasses}>
            中学校
          </th>
        </tr>
        {juniorHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        {/* <tr>
          <th rowSpan={4} className={tableClasses}>
            高等学校
          </th>
        </tr> */}
        {/* {allDayHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        <tr>
          <th rowSpan={5} className={tableClasses}>
            高等学校
            <br />
            (定時制)
          </th>
        </tr>
        {fixTimeHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))} */}
      </tbody>
    </table>
  )
}

const ReportBodyPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  let city = getCity()
  const prefectureCode = 8
  const [year, setYear] = useState(2024)
  const [gender, setGender] = useState(UserGender.Male)

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">体格の平均値及び標準偏差</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="pb-8 space-x-4 space-y-4">
            <Select value={year} onChange={(v) => setYear(v)}>
              <Option value={2010}>2010 ({makeYearName(2010)})</Option>
              <Option value={2011}>2011 ({makeYearName(2011)})</Option>
              <Option value={2012}>2012 ({makeYearName(2012)})</Option>
              <Option value={2013}>2013 ({makeYearName(2013)})</Option>
              <Option value={2014}>2014 ({makeYearName(2014)})</Option>
              <Option value={2015}>2015 ({makeYearName(2015)})</Option>
              <Option value={2016}>2016 ({makeYearName(2016)})</Option>
              <Option value={2017}>2017 ({makeYearName(2017)})</Option>
              <Option value={2018}>2018 ({makeYearName(2018)})</Option>
              <Option value={2019}>2019 ({makeYearName(2019)})</Option>
              <Option value={2020}>2020 ({makeYearName(2020)})</Option>
              <Option value={2021}>2021 ({makeYearName(2021)})</Option>
              <Option value={2022}>2022 ({makeYearName(2022)})</Option>
              <Option value={2023}>2023 ({makeYearName(2023)})</Option>
              <Option value={2024}>2024 ({makeYearName(2024)})</Option>
            </Select>

            <Select value={gender} onChange={(v) => setGender(v)}>
              <Option value={UserGender.Male}>男子</Option>
              <Option value={UserGender.Female}>女子</Option>
            </Select>
          </div>

          <ReportBodyTable
            prefectureCode={prefectureCode}
            cityCode={city.code}
            year={year}
            gender={gender}
          />

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>体格の平均値及び標準偏差</div>
        <div>{gender === UserGender.Male ? '男子' : '女子'}</div>
        <ReportBodyTable
          prefectureCode={prefectureCode}
          cityCode={city.code}
          year={year}
          gender={gender}
        />
      </div>
    </Dashboard>
  )
}

export default ReportBodyPage
